// import React, { useState, useEffect } from 'react';
// import { useForm, Controller } from 'react-hook-form';
// import BasicData from './logic/basicData';
// import SelectionOfCountry from './logic/country';
// import MerchanInformation from './logic/merchanData';
// import 'bootstrap/dist/css/bootstrap.css';
// import axios from "axios";

// const muchasGracias = () => { 
//   alert("Datos guardados correctamente 😊");
// };

// export default function MyForm() {
//   const { handleSubmit, control, reset } = useForm();
//   const [showButton, setShowButton] = useState(false);
//   const [pendientes, setPendientes] = useState([]);
//   const [area, setArea] = useState('');
//   const [number, setNumber] = useState('');
//   const guardar = window.localStorage;



//   useEffect(() => {
//     const traerLista = JSON.parse(guardar.getItem('subir')) || [];
//     setPendientes(traerLista);
//   }, []);

//   const transformData = (data) => {
//     var params = new URLSearchParams();
//     for (const key in data) {
//       if (data.hasOwnProperty(key)) {
//         params.append(key, data[key]);
//       }
//     }
//     return params;
//   };

//   const convertSearchParamsToObject = (params) => {
//     const obj = {};
//     for (const [key, value] of params.entries()) {
//       obj[key] = value;
//     }
//     return obj;
//   };

//   const agregarALista = (data, item) => {
//     try {
//       let traerLista = guardar.getItem(item);
//       traerLista = traerLista ? JSON.parse(traerLista) : [];
//       const dataObject = convertSearchParamsToObject(data);
//       traerLista.push(dataObject);
//       guardar.setItem(item, JSON.stringify(traerLista));
//       setPendientes(traerLista);
//     } catch (error) {
//       console.error('Error in agregarALista:', error);
//     }
//   };

//   const config = {
//     headers: {
//       'Content-Type': 'application/x-www-form-urlencoded'
//     }
//   };




//   const transformAndDownloadCSV = (data) => {
//     const headers = Object.keys(data[0]); // Obtenemos las claves del primer objeto como headers
  
//     const csvContent = "data:text/csv;charset=utf-8," +
//                        headers.join(",") + "\n" +
//                        data.map(row => headers.map(header => row[header]).join(",")).join("\n");
  
//     const encodedUri = encodeURI(csvContent);
//     const link = document.createElement("a");
//     link.setAttribute("href", encodedUri);
//     link.setAttribute("download", "data.csv");
//     document.body.appendChild(link);
//     link.click();
//   };

    
//   const onSubmit = (data) => {
//     const currentDate = new Date();
//     const year = currentDate.getFullYear();
//     const month = String(currentDate.getMonth() + 1).padStart(2, '0');
//     const day = String(currentDate.getDate()).padStart(2, '0');
//     const hours = String(currentDate.getHours()).padStart(2, '0');
//     const minutes = String(currentDate.getMinutes()).padStart(2, '0');
//     const seconds = String(currentDate.getSeconds()).padStart(2, '0');

//     const MyDateString = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

//     if(data.marca ===  ''  || data.marca === 'undefined' || data.marca === null){
//       data.marca = null;
//     }

//     const formData = {
//       ...data,
//       tel:area+number,
//       fecha_formulario: MyDateString,
//     };

   
//     console.log(formData);
//     setArea('');
//     setNumber('');

// if (true){ 
//   axios.post(`http://formulario/back/back.php?t=encuesta&id=${id}`, params, config)
//     .then(response => {
//       muchasGracias();
//       console.log('Respuesta del servidor:', response.data);
//       console.log('Operación exitosa:', response.data.success);
//       console.log('Mensaje:', response.data.message);
//     })
//     .catch(error => {
//       agregarALista(params,'subir')
//       muchasGracias();
//       console.error('Error al enviar el formulario:', error);
//     })
// }
// else {
// muchasGracias();
// agregarALista(params,'subir');
// } 
//     var params = transformData(formData);
//     agregarALista(params, 'subir');
//     reset({
//       nombre: '',
//       apellido: '',
//       email: '',
//       tel: '',
//       mascota: '',
//       marca: '',
//       fecha_formulario: '',
//       usuario_registra: '',
//     });
//     muchasGracias();
//   };

//   return (
//     <div className="container">
//       <script type="module">
//         import 'https://cdn.jsdelivr.net/npm/@pwabuilder/pwaupdate';
//         const el = document.createElement('pwa-update');
//         document.body.appendChild(el);
//       </script>
//       <div className="row justify-content-center">
//         <div className="col-12 mx-auto">
//           <div className="card shadow rounded justify-content-center p-4">
//             <div className="row">
//               <div className="col-6 text-center">
//                 Pendientes a subir: {pendientes.length}
//               </div>
//               <div className="col-6 text-center">
//                 {showButton && (
//                   <button type="button" className="btn btn-sm btn-success" onClick={submitInfoLocalStorage}>Subir pendientes</button>
//                 )}
//               </div>
//             </div>
//             <div className="card-body">
//               <nav className="navbar navbar-light bg-light shadow">
//                 <div className="container">
//                   <a className="navbar-brand m-auto">
//                     <img src="https://sieger.com.ar/images/logo-header-dark.svg" alt="" style={{ width: "140px" }} />
//                   </a>
//                 </div>
//               </nav>
//               <form onSubmit={handleSubmit(onSubmit)} className='form-control'>
//                 <BasicData control={control} setArea={setArea} setNumber={setNumber} />
//                 <SelectionOfCountry onSelectionChange={onSubmit} />
//                 <MerchanInformation control={control} />
//                 <button className="btn btn-danger btn-registrar w-100 mt-5 p-3 fw-bold" type="submit">Enviar</button>
//               </form>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }


import React, { useState, useEffect } from 'react';
import { useForm, Controller, set } from 'react-hook-form';
import BasicData from './logic/basicData';
import SelectionOfCountry from './logic/country';
import WorkData from './logic/workData';
import MerchanInformation from './logic/merchanData';
import 'bootstrap/dist/css/bootstrap.css';
import axios from "axios";
import '@pwabuilder/pwaupdate';

const muchasGracias = () => {

  alert("Datos guardados correctamente 😊")

};

export default function MyForm() {

  const { handleSubmit, control, reset } = useForm();
  const [showButton, setShowButton] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [pendientes, setPendientes] = useState([]);
  const [area, setArea] = useState('');
  const [number, setNumber] = useState('');
  const guardar = window.localStorage;

  const handleSelectionChange = ({ country, state, city }) => {
    setSelectedCountry(country);
    setSelectedState(state);
    setSelectedCity(city);
  };

  useEffect(() => {
    const checkTime = () => {
      const now = new Date();
      if ((now.getHours() >= 8) && (navigator.onLine)) {
        setShowButton(true);
      }
    };

    checkTime();
    const interval = setInterval(checkTime, 60000);

    return () => clearInterval(interval);
  }, []);


  function verPendientes(){
    var traerLista = JSON.parse(guardar.getItem('subir'));
    return traerLista ? traerLista.length : 0;
  }

  const transformData = (data) => {
    var params = new URLSearchParams();
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        params.append(key, data[key]);
      }
    }
    return params;
  };


  const convertSearchParamsToObject = (params) => {
    const obj = {};
    for (const [key, value] of params.entries()) {
      obj[key] = value;
    }
    return obj;
  };
  
  const agregarALista = (data, item) => {
    try {
      let traerLista = guardar.getItem(item);
      traerLista = traerLista ? JSON.parse(traerLista) : [];
      const dataObject = convertSearchParamsToObject(data);
      traerLista.push(dataObject);
  
      guardar.setItem(item, JSON.stringify(traerLista));
    } catch (error) {
      console.error('Error in agregarALista:', error);
    }
  };

    const config = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  };

    const submitInfoLocalStorage = () => {
    var traerLista = JSON.parse(guardar.getItem('subir'));
    console.log(traerLista);
    // transformAndDownloadCSV(traerLista);
    if(navigator.onLine){
    traerLista.forEach(element => {
      console.log(element);
      var id = window.location.pathname.split('/').pop();
      axios.post(`https://forms.sieger.com.ar/back/back.php?t=encuesta&id=${id}`, element, config)
        .then(response => {
          console.log('Respuesta del servidor:', response.data);
          console.log('Operación exitosa:', response.data.success);

          guardar.setItem('subir', JSON.stringify([]));
          setPendientes([]);
        })
        .catch(error => {
          console.error('Error al enviar el formulario:', error);
        });
    });
  }
  else {
    alert('No hay conexión a internet');
  } 

  }


const onSubmit = (data) => {
    const formData = {
      ...data,
      tel: area + number,
      pais: selectedCountry,
      provincia: selectedState,
      ciudad: selectedCity,
    };
    
    var params = transformData(formData);
    console.log(params.toString());
    agregarALista(params, 'subir');
    setSelectedCity('');
    setSelectedState('');
    setSelectedCountry('');
    setArea(''); 
    setNumber(''); 
    

    reset({
      nombre: '',
      apellido: '',
      email: '',
      tel: '',
      area: '',
      number: '',
      usuario_registra: '',
      observaciones: '',
      pais: '',
      provincia: '',
      ciudad: '',
      tipocli: '',
      tienelocal: '',
      conocesieger: '',
      vendeSieger: '',
      duenoempleado: '',
      direccionlocal: '',
    });


    muchasGracias();
    window.location.reload();
};
  return (
    <div className="container">
      <script type="module">
        import 'https://cdn.jsdelivr.net/npm/@pwabuilder/pwaupdate';
        const el = document.createElement('pwa-update');
        document.body.appendChild(el);
      </script>
      <div className="row justify-content-center">
        <div className="col-6 text-center">
          {showButton && (
            <button type="button" className="btn btn-sm btn-success" onClick={submitInfoLocalStorage}>Subir pendientes</button>
          )}
        </div>
        <div className="col-12 mx-auto">
          <div className="card shadow rounded justify-content-center p-4">
            <div className="card-body">
              <nav className="navbar navbar-light bg-light shadow">
                <div className="container">




                 <a className="navbar-brand m-auto">
                   <img src="https://sieger.com.ar/images/logo-header-dark.svg" alt="" style={{ width: "140px" }} />
                </a>
                    <p> Hay {verPendientes()} pendientes de subir</p>
                  </div>
                </nav>

              <form onSubmit={handleSubmit(onSubmit)} className='form-control'>
                <Controller name="evento"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <div className='row'>
                      <label>
                        <input
                          type="radio"
                          value="JornadasTanti"
                          {...field}
                          checked={field.value === "JornadasTanti"}
                          onChange={() => field.onChange("JornadasTanti")}
                        />
                        Jornadas de Actualización Veterinaria en Tanti
                      </label>
                      <label>
                        <input
                          type="radio"
                          value="CongresoSantaFe"
                          {...field}
                          checked={field.value === "CongresoSantaFe"}
                          onChange={() => field.onChange("CongresoSantaFe")}
                        />
                       Congreso de Medicina Veterinaria de Santa Fe
                      </label>
                    </div>
                  )}
                />
                <BasicData control={control} setArea={setArea} setNumber={setNumber} /> 
                <SelectionOfCountry control={control} onSelectionChange={handleSelectionChange} />
                <MerchanInformation control={control} />
                <WorkData control={control} />
                {/* <ProductPromotions control={control} /> */}
                <Controller
                  name="observaciones"
                  control={control}
                  render={({ field }) => (
                    <div>
                    <label> Observaciones </label>
                    <textarea
                      className="form-control"
                      placeholder="Opcional"
                      {...field}
                    /> </div>
                  )} />
                <button className="btn btn-danger btn-registrar w-100 mt-5 p-3 fw-bold" type="submit">Enviar</button>
              </form>            
              </div>
            </div>
          </div>
        </div>
      </div>
  );
}