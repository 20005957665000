import React, { useState, useEffect } from "react";
import Papa from "papaparse";

export default function SelectionOfCountry({ onSelectionChange }) {
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [countryId, setCountryId] = useState(0);
  const [stateId, setStateId] = useState(0);
  const [countryName, setCountryName] = useState("");
  const [stateName, setStateName] = useState("");
  const [cityName, setCityName] = useState("");

  useEffect(() => {
    Papa.parse("/data/countries.csv", {
      download: true,
      header: true,
      complete: (results) => {
        setCountries(results.data);
      },
    });
  }, []);
  
  useEffect(() => {
    if (countries.length > 0) {
      const allowedCountries = [
        "11", "27", "31", "44", "48", "64", "66", "90", "172", "173", "235", "239", "233"
      ];
      setCountries(countries.filter(country => allowedCountries.includes(country.id)));
    }
  }, [countries]);
  
  useEffect(() => {
    if (countryId) {
      Papa.parse("/data/states.csv", {
        download: true,
        header: true,
        complete: (results) => {
          setStates(results.data.filter((state) => state.country_code === countryId));
        },
      });
    }
  }, [countryId]);
  
  const handleCountryChange = (e) => {
    const selectedCountry = countries.find((country) => country.iso2 === e.target.value);
    setCountryId(e.target.value); 
    setCountryName(selectedCountry ? selectedCountry.name : "");
    setStateId(0);
    setStateName("");
    setCityName("");
  };
  
  const handleStateChange = (e) => {
    const selectedState = states.find((state) => state.id === e.target.value);
    setStateId(e.target.value); // Usar id del estado como stateId
    setStateName(selectedState ? selectedState.name : "");
    setCityName("");
  };
  
  useEffect(() => {
    if ((stateName === "Buenos Aires") || (stateId !== 4880)) {
      Papa.parse("/data/cities.csv", {
        download: true,
        header: true,
        complete: (results) => {
          setCities(results.data.filter((city) => city.state_id === stateId));
        },
      });
    }
  }, [stateId]);
  
  
  const handleCityChange = (e) => {
    setCityName(e.target.value);
    console.log(e.target.value)
  };

  useEffect(() => {
    handleSelectionChange();
  }, [cityName]);

  const handleSelectionChange = () => {
    onSelectionChange({
      country: countryName,
      state: stateName,
      city: cityName,
    });
  };

  return (
    <div>
      <label >Pais</label>
      <select value={countryId} onChange={handleCountryChange} required className="form-control">
        <option value="" hidden>Seleccione un país</option>
        {countries.map((country) => (
          <option key={country.iso2} value={country.iso2}>{country.name}</option>
        ))}
      </select>
      
      <label>Provincia</label>
      <select className="form-control" value={stateId} required onChange={handleStateChange}>
        <option value="" hidden>Seleccione una provincia</option>
        {states.map((state) => (
          <option key={state.state_code} value={state.id}>
            {state.name}
          </option>
        ))}
      </select>

      
        <div>
          <label>Ciudad</label>
          <select className="form-control" value={cityName} required onChange={handleCityChange}>
            <option value="" hidden>Seleccione una ciudad</option>
            {cities.map((city) => (
              <option key={city.id} value={city.name}>
                {city.name}
              </option>
            ))}
          </select>
        </div>
    </div>
  );
}
