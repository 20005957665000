import { Controller, useWatch } from 'react-hook-form';
import React from "react";
import 'bootstrap/dist/css/bootstrap.css';
export default function BasicData({ control, setArea, setNumber }) {
    // const mascotaValue = useWatch({ control, name: "mascota" });
return (
    <div>
        <Controller
            name="nombre"
            control={control}
            render={({ field }) => (
                <input
                    type="text"
                    required={true}
                    className="form-control mb-4 input-nombre"
                    placeholder="Nombre"
                    autoComplete="off"
                    {...field}
                />
            )}
        />

        <Controller
            name="apellido"
            control={control}
            render={({ field }) => (
                <input
                    type="text"
                    required={true}
                    className="form-control mb-4 "
                    autoComplete="off"
                    placeholder="Apellido"
                    {...field}
                />
            )}
        />

        <Controller
            name="email"
            control={control}
            render={({ field }) => (
                <input
                    type="email"
                    required={true}
                    autoComplete="off"
                    className="form-control mb-3"
                    placeholder="Email"
                    {...field}
                />
            )}
        />
        <label> Número de teléfono </label>

        <div className="row">
            <div className="col-5">
                <input
                    type="tel"
                    required={true}
                    minLength={2}
                    maxLength={4}
                    className="form-control mb-3"
                    placeholder="(011)"
                    onInput={(event) => {
                        event.target.value = event.target.value.replace(/[^0-9]/g, "");
                        setArea(event.target.value);
                    }}
                />
            </div>
            <div className="col-7">
                <input
                    type="tel"
                    required={true}
                    className="form-control mb-3"
                    placeholder="12345678"
                    maxLength={8}
                    onInput={(event) => {
                        event.target.value = event.target.value.replace(/[^0-9]/g, "");
                        setNumber(event.target.value);
                    }}
                />
            </div>
        </div>

        {/* <Controller
            name="mascota"
            control={control}
            required={true}
            className="form-control mb-3"
            placeholder="Mascota"
            render={({ field }) => (
                <>
                    <label> Mascota </label>
                    <select {...field} required className="form-control">
                        <option value="" hidden>
                            Elija una opción
                        </option>
                        <option value="PERRO">Perro</option>
                        <option value="GATO">Gato</option>
                        <option value="PERRO Y GATO"> Perro y Gato</option>
                        <option value="NO TIENE">No tiene</option>
                    </select>
                </>
            )}
        /> */}

        {/* {mascotaValue !== "NO TIENE" && (
            <Controller
                name="marca"
                control={control}
                required={true}
                className="form-control mb-3"
                placeholder="Marca"
                render={({ field }) => (
                    <>
                        <label> Marca </label>
                        <select {...field} required className="form-control">
                            <option value="No tiene" selected hidden>
                                Elija una opción
                            </option>
                            <option value="Agility">Agility</option>
                            <option value="Belcan">Belcan</option>
                            <option value="Belcat">Belcat</option>
                            <option value="Biopet">Biopet</option>
                            <option value="Cat Chow">Cat Chow</option>
                            <option value="Dog Chow">Dog Chow</option>
                            <option value="Dogui">Dogui</option>
                            <option value="Eukanuba">Eukanuba</option>
                            <option value="Exact">Exact</option>
                            <option value="Excellent">Excellent</option>
                            <option value="Fawna">Fawna</option>
                            <option value="Gati">Gati</option>
                            <option value="Golocan seco">Golocan seco</option>
                            <option value="Gooster">Gooster</option>
                            <option value="Hills">Hills</option>
                            <option value="Homemade Delights">Homemade Delights</option>
                            <option value="Iams">Iams</option>
                            <option value="Infinity">Infinity</option>
                            <option value="Juvenia">Juvenia</option>
                            <option value="Ken-L">Ken-L</option>
                            <option value="Kongo">Kongo</option>
                            <option value="Kroffy">Kroffy</option>
                            <option value="Livra">Livra</option>
                            <option value="Maintenance criadores">
                                Maintenance criadores
                            </option>
                            <option value="Maxxium">Maxxium</option>
                            <option value="Mira">Mira</option>
                            <option value="Mon Ami">Mon Ami</option>
                            <option value="Natural">Natural</option>
                            <option value="Natural Meat">Natural Meat</option>
                            <option value="Nature">Nature</option>
                            <option value="Nufood">Nufood</option>
                            <option value="Nutrience">Nutrience</option>
                            <option value="Nutrique">Nutrique</option>
                            <option value="Old Prince">Old Prince</option>
                            <option value="Old prince Premium">Old prince Premium</option>
                            <option value="Old Prince receta original">
                                Old Prince receta original
                            </option>
                            <option value="Origen by company (Baires)">
                                Origen by company (Baires)
                            </option>
                            <option value="Pedigree">Pedigree</option>
                            <option value="Performance">Performance</option>
                            <option value="ProPlan">ProPlan</option>
                            <option value="Proterline">Proterline</option>
                            <option value="Raza">Raza</option>
                            <option value="Royal Canin">Royal Canin</option>
                            <option value="Sabrositos">Sabrositos</option>
                            <option value="Sieger">Sieger</option>
                            <option value="Tiernitos">Tiernitos</option>
                            <option value="Top Nutrition">Top Nutrition</option>
                            <option value="Total Balance">Total Balance</option>
                            <option value="Unik">Unik</option>
                            <option value="Vet plus">Vet plus</option>
                            <option value="Vitalcan Balanced">Vitalcan Balanced</option>
                            <option value="Vitalcan Balanced Natural">
                                Vitalcan Balanced Natural
                            </option>
                            <option value="Vitalcan Complete">Vitalcan Complete</option>
                            <option value="Vitalcan Línea V">Vitalcan Línea V</option>
                            <option value="Vitalcan Premium">Vitalcan Premium</option>
                            <option value="Voraz">Voraz</option>
                            <option value="Whiskas">Whiskas</option>
                            <option value="Whole Earth Farms Purina">
                                Whole Earth Farms Purina
                            </option>
                            <option value="Wild">Wild</option>
                            <option value="Otras marcas">Otras marcas</option>
                        </select>
                    </>
                )}
            />
        )}

        {mascotaValue === "NO TIENE" && (
            <Controller
                name="marca"
                control={control}
                className="form-control mb-3"
                placeholder="Marca"
                render={({ field }) => (
                    <>
                        <label> Marca </label>
                        <select {...field} className="form-control">
                            <option value="" selected>
                                No consume
                            </option>
                        </select>
                    </>
                )}
            />
        )} */}
    </div>
);
}

