import React from "react";
import { Controller } from "react-hook-form";
import 'bootstrap/dist/css/bootstrap.css';
import "../visual/custom.css";

export default function MerchanInformation({ control }) {
    return (
        <div>
            <Controller
                name="usuario_registra"
                control={control}
                required={true}
                className="form-control"
                render={({ field }) => (
                    <div>
                        <label>¿Quién registró el dato?</label>
                        <select {...field} required className="form-control" >
                            <option hidden>Elija una opción</option>
                            <option value="Promovendedora">Promovendedora</option>
                            {/* <option value="Agustina Carrizo">Agustina Carrizo</option>
                            <option value="Agustina Zaro">Agustina Zaro</option>
                            <option value="David Raminy">David Raminy</option>
                            <option value="Gustavo Poveda">Gustavo Poveda</option>
                            <option value="Joseany Mendez">Joseany Mendez</option>
                            <option value="Lucas Scarmato">Lucas Scarmato</option>
                            <option value="Lucas Valinoti">Lucas Valinoti</option>
                            <option value="Lucia Martinucci">Lucia Martinucci</option>
                            <option value="Lourdes Toloza">Lourdes Toloza</option>
                            <option value="Manuel Pardo">Manuel Pardo</option>
                            <option value="María Herrera">María Herrera</option>
                            <option value="María Ismael">María Ismael</option>
                            <option value="Matias Perry">Matias Perry</option>
                            <option value="Mónica Ahumada">Mónica Ahumada</option>
                            <option value="Nahuel Erdocia">Nahuel Erdocia</option>
                            <option value="Rosario Battaini">Rosario Battaini</option>
                            <option value="Silvina Andrade">Silvina Andrade</option> */}
                            <option value="Otro">Otro</option>
                        </select>
                    </div>
                )}
            />
        </div>
    );
}
