import React, { useState } from "react";
import { Controller, useWatch } from "react-hook-form";
import 'bootstrap/dist/css/bootstrap.css';

export default function WorkData({ control }) {
  const [vet, setVet] = useState(false);
  const [student, setStudent] = useState(false);
  const [other, setOther] = useState(false);

  const tienetrabajaenlocalValue = useWatch({ control, name: "tienelocal" });
  const vendesiegerValue = useWatch({ control, name: "vendeSieger" });
  return (
    <div>
      <label>¿Sos estudiante o veterinario?</label>
      <Controller
        name="tipocli"
        control={control}
        required={true}
        className="form-control"
        render={({ field }) => (
          <select
          className="form-control"
            {...field}
            onChange={(e) => {  
              const selectedValue = e.target.value;
              field.onChange(selectedValue);
              setVet(selectedValue === "Veterinario");
              setStudent(selectedValue === "Estudiante");
              setOther(selectedValue === "Otro");
            }}
            
          >
            
            <option className="form-control/" value="" hidden>Elija una opción</option>
            <option value="Veterinario">Veterinario/a</option>
            <option value="Estudiante">Estudiante</option>
            <option value="Otro">Otro</option>
          </select>
        )}
      />

      {vet || student ? (
        <div>
          <Controller
            className="form-control"
            required={true}
            name="tienelocal"
            control={control}
            render={({ field }) => (
              <div>
              <label>¿Trabajas en veterinaria?</label>
              <select {...field} className="form-control">
                <option value="" hidden>Elija una opción</option>
                <option value="Si">Sí</option>
                <option value="No">No</option>
                <option value="Realiza Domiciliaria">Domiciliarias</option>
              </select>
              </div>
            )}
          />
          {tienetrabajaenlocalValue === "Si" ? (
            <div>
            <Controller 
                name="duenoempleado"
                control={control}
                className="form-control"
                required={true}
                render={({ field }) => (
                  <div>
                    <label>¿Eres dueño/a o empleado/a?</label>
                    < select {...field} className="form-control">
                        <option value=""hidden>Elija una opción</option>
                        <option value="Dueno">Dueño/a</option>
                        <option value="Empleado">Empleado/a</option>  
                    </select> </div>
                    )}
            />



            <Controller
              name="nombrelocal"
              control={control}
              className="form-control"
              render={({ field }) => (
                <input
                  type="text"
                  className="form-control"
                  placeholder="Nombre de la veterinaria"
                  {...field}
                />
              )}
            />

            <Controller
              name="direccionlocal"
              control={control}
              className="form-control"
              render={({ field }) => (
                <div className="py-2">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Dirección de la veterinaria"
                  {...field}
                />
                </div>
              )}
            />

            <Controller 
                name="vendeSieger"
                control={control}
                className="form-control"
                required={true}
                render={({ field }) => (
                  <div>
                    <label> ¿Vendes Sieger? </label>
                    < select {...field} className="form-control">
                        <option value="" hidden>Elija una opción</option>
                        <option value="Si">Sí</option>
                        <option value="No">No</option>  
                    </select>
                  </div>
                    )}
            />
            {vendesiegerValue === "No" ? (
              <Controller 
                  name="contactarlo"
                  control={control}
                  className="form-control"
                  required={true}
                  render={({ field }) => (
                    <div>
                      <label> ¿Quieres que un asesor comercial te contacte? </label>
                      <select {...field} className="form-control">
                          <option value="" hidden>Elija una opción</option>
                          <option value="Si">Sí</option>
                          <option value="No">No</option>  
                      </select>
                    </div>)}
              />
            ) : null}
            </div>
          ) : null}
        </div>
      ) : other ? (<div>
        <Controller 
            name="nombrelocal"
            control={control}
            className="form-control"
            required={true}
            render={({ field }) => (
              <div>
                <label></label>
                <input
                type="text"
                className="form-control"
                placeholder="Nombre de lugar de trabajo"
                {...field}
                /> </div>
            )}
        />
      </div>) : null}

    </div>
  );
}
